<!--  聊天记录  -->
<template>
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="手机号：">
          <el-input maxlength="11" placeholder="请输入手机号" v-model="queryForm.phone"
            @input="(e) => (queryForm.phone = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="聊天记录">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(row)" v-if="isShowBtn('5490175b5d464941826f7115')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe >
          <el-table-column  prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="chat_count" label="提问次数"></el-table-column>
          <el-table-column prop="principal" label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(scope.row)" v-if="isShowBtn('5490175b5d464941826f7115')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
          <el-pagination @size-change="sizeChange" @current-change="currentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 查看聊天记录弹框 -->
    <lebo-dialog append-to-body title="查看聊天记录" :isShow="showLookChatLog" width="50%" @close="showLookChatLog = false"
      footerSlot closeOnClickModal>
      <lookChatLog @closeDialog="closelookChatLog" v-if="showLookChatLog" :id="dialogshowLookChatLogId"></lookChatLog>
    </lebo-dialog>
  </div>
</template>

<script>
import { getAITalkPageList } from '@/api/maintenanceCenter'
import lookChatLog from './components/index.vue'
export default {
  name: 'ChatLog',
  components: { lookChatLog },
  props: {},
  data () {
    return {
      // 查询表单
      queryForm: {
        phone: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      showLookChatLog: false, // 查看聊天详情弹框
      dialogshowLookChatLogId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '提问次数',
            prop: 'chat_count'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取聊天记录列表
    async fnGetAITalkPageList () {
      const res = await getAITalkPageList(this.queryForm)
      console.log('聊天记录', res)
      this.tableData = res.Data.DataList ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAITalkPageList()
    },
    // 每页条数改变
    sizeChange (val) {
      this.page.currentPage = 1
      this.page.pageSize = val
      this.queryForm.PageSize = val
      this.fnGetAITalkPageList()
    },
    // 页码改变
    currentChange (val) {
      this.queryForm.PageIndex = val
      this.page.currentPage = val
      this.fnGetAITalkPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 关闭弹框
    closelookChatLog () {
      this.showLookChatLog = false
      this.fnGetAITalkPageList()
    },
    // 查看具体问答记录
    lookingfor (row) {
      this.dialogshowLookChatLogId = row._id
      this.showLookChatLog = true
    }
  },
  created () { },
  mounted () {
    this.fnGetAITalkPageList()
  }
}
</script>

<style scoped></style>
