<!-- 查看聊天记录 -->
<template>
  <div class='content'>
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <!-- <el-form-item label="问题：">
        <el-input placeholder="请输入问题：" v-model="queryForm.questions" @keyup.enter. native="onSubmit"></el-input>
      </el-form-item>
      <el-form-item label="答案：">
        <el-input placeholder="请输入答案：" v-model="queryForm.answers" @keyup.enter. native="onSubmit"></el-input>
      </el-form-item> -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="查询：">
            <el-input placeholder="请输入关键字：" v-model="filterKey" @keyup.enter="onSubmit"></el-input>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <el-form-item>
            <div class="searchResultBox" v-if="num !== 0">
              <span>共{{ num !== 0 ? indexNum + 1 : 0 }}/{{ num }}条</span>
              <div class="searchButton">
                <div class="el-icon-caret-top" @click="prev('pre')"></div>
                <div class="el-icon-caret-bottom" @click="next('next')"></div>
              </div>
            </div>

          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <ul class="mainChatLog">
      <template v-if="!emptyResult">
        <li v-for="(item, index) in personQuestionList" :key="index">
          <div class="timeBox" v-if="item.create_time">
            <div>{{ $moment(item.create_time).isSame($moment(), "day") ? $moment(item.create_time).format('HH:mm') :
              $moment(item.create_time).format('M月D日 HH:mm') }}</div>
          </div>
          <div class="chatlog">
            <div class="questionsBox" v-if="item.questions.length > 0">
              <div class="spanBoxRight" v-html="item.questions"></div>
              <div class="headPic icon iconfont lebo-userpic"></div>
            </div>
            <div class="answersBox">
              <div class="headPic " style="background-color: #0049FF;">
                <div class="headPicInner"></div>
              </div>
              <div class="spanBoxLeft">
                <template v-if="item.answer_type === 1">
                  {{ item.answers }}
                </template>
                <template v-else>
                  <el-image fit="fill" :src="getFirstImage(item.answers)" style="width:200px;" :z-index="10000"
                    @click="onpenImage(getFirstImage(item.answers))">
                    <div slot="error" class="image-error">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>

                </template>
              </div>
            </div>
          </div>
        </li>
      </template>
      <template v-else>
        <div class="emptylog">
          <span>没有找到与"</span><span style="color:#02a7f0;">{{ filterKey }}</span><span>"相关的聊天记录</span>
        </div>
      </template>
    </ul>
    <cusImage v-if="showImage" @close="showImage = false" :url-list="urlList" style="width:100%;">
    </cusImage>
  </div>
</template>

<script>
import { getPersonAITalkPageList } from '@/api/maintenanceCenter'
import cusImage from '../../components/index.vue'

export default {
  name: 'lookChatLog',
  components: { cusImage },
  props: ['id'],
  data () {
    return {
      personQuestionList: [],
      queryForm: {
        _id: '',
        answers: '',
        questions: ''
      },
      filterKey: '', // 查询关键字
      originList: [],
      num: 0,
      indexNum: 0,
      emptyResult: false,
      showImage: false,
      urlList: []
    }
  },
  watch: {},
  computed: {
  },
  methods: {
    // 获取个人聊天记录
    async fngetPersonAITalkPageList () {
      const res = await getPersonAITalkPageList({ _id: this.id })
      console.log('个人聊天记录', res)
      if (res.Data.length > 0) {
        let showTime = '1900-01-01'
        this.personQuestionList = res.Data.map(item => {
          const duringTime = this.$moment(item.create_time).diff(this.$moment(showTime), 'minutes')
          console.log('duringTime', duringTime)
          if (Math.abs(duringTime) < 10) {
            item.create_time = ''
          } else {
            showTime = item.create_time
          }
          return item
        })
        console.log('personQuestionList', this.personQuestionList)
      } else {
        this.personQuestionList = []
      }
      this.originList = this.personQuestionList
    },
    // 查询具体问题
    onSubmit () {
      // const res = await getPersonAITalkPageList(this.queryForm)
      // console.log('具体问题', res)
      // if (res.Data.length > 0) {
      //   let showTime = '1900-01-01'
      //   this.personQuestionList = res.Data.map(item => {
      //     const duringTime = this.$moment(item.create_time).diff(this.$moment(showTime), 'minutes')
      //     console.log('duringTime', duringTime)
      //     if (Math.abs(duringTime) < 10) {
      //       item.create_time = ''
      //     } else {
      //       showTime = item.create_time
      //     }
      //     return item
      //   })
      // } else {
      //   this.personQuestionList = []
      // }
      this.num = 0
      this.indexNum = 0
      this.emptyResult = false
      const change = this.personQuestionList
      change.forEach(item => {
        const reg1 = new RegExp('<em style="background-color: yellow">', 'g')
        const reg2 = new RegExp('<em style="background-color: yellow;color:black;">', 'g')
        const reg3 = new RegExp('</em>', 'g')
        item.answers = item.answers.replace(reg1, '')
        item.questions = item.questions.replace(reg2, '')
        item.answers = item.answers.replace(reg3, '')
        item.questions = item.questions.replace(reg3, '')
      })
      if (this.filterKey) {
        change.forEach(item => {
          const reg = new RegExp(this.filterKey, 'g')
          item.answers = item.answers.replace(reg, '<em style="background-color: yellow">' + this.filterKey + '</em>')
          item.questions = item.questions.replace(reg, '<em style="background-color: yellow;color:black;">' + this.filterKey + '</em>')
        })
        this.$nextTick(() => {
          this.searchGaoLian()
        })
      } else {
        const list = document.querySelector('.mainChatLog')
        list.scrollTop = 0
      }
    },
    // 设置高亮
    searchGaoLian () {
      const searchVal = this.filterKey
      const num = document.getElementsByTagName('em').length
      this.num = num
      console.log('this.num', this.num)
      if (num !== 0) {
        // 使用.style.background会出现em删不掉会一直叠加的情况 因此只能替换innerHTML
        document.getElementsByTagName('em')[0].innerHTML = '<strong style="background-color: #ff9632">' + searchVal + '</strong>'
        // 滚动到第一个关键字位置
        document.getElementsByTagName('em')[0].scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
        this.emptyResult = false
      } else {
        this.emptyResult = true
      }
    },
    // 下一个
    next () {
      if (this.num !== 0) {
        for (let i = 0; i < document.getElementsByTagName('em').length; i++) {
          document.getElementsByTagName('em')[i].innerHTML = this.filterKey
        }
        if (this.indexNum === this.num - 1) {
          this.indexNum = 0
        } else {
          this.indexNum = this.indexNum + 1
        }
        document.getElementsByTagName('em')[this.indexNum].innerHTML =
          '<strong style="background-color: #ff9632">' +
          this.filterKey +
          '</strong>'
        document.getElementsByTagName('em')[this.indexNum].scrollIntoView()
        const list = document.querySelector('.mainChatLog')
        list.scrollTop -= 80
        // document.documentElement.scrollTop -= 80
      }
    },
    // 上一个
    prev () {
      if (this.num !== 0) {
        for (let i = 0; i < document.getElementsByTagName('em').length; i++) {
          document.getElementsByTagName('em')[i].innerHTML = this.filterKey
        }
        if (this.indexNum === 0) {
          this.indexNum = this.num - 1
        } else {
          this.indexNum = this.indexNum - 1
        }
        document.getElementsByTagName('em')[this.indexNum].innerHTML =
          '<strong style="background-color: #ff9632">' +
          this.filterKey +
          '</strong>'
        document.getElementsByTagName('em')[this.indexNum].scrollIntoView()
        const list = document.querySelector('.mainChatLog')
        list.scrollTop -= 80
        // document.documentElement.scrollTop -= 80
      }
    },
    onpenImage (url) {
      this.urlList = []
      this.urlList = [url]
      this.showImage = true
    }
  },
  created () { },
  mounted () {
    this.queryForm._id = this.id
    this.fngetPersonAITalkPageList()
  }
}
</script>

<style scoped lang="less">
.mainChatLog {
  width: 100%;
  box-sizing: border-box;
  max-height: 500px;
  overflow-y: auto;

  .spanBoxLeft {
    font-size: 14px;
    line-height: 26px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    max-width: 80%;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    color: #333;
    text-align: left;
    position: relative;
    background-color: #F2F2F2;

    &::before {
      display: inline-block;
      content: "";
      border: 8px solid transparent;
      border-right: 8px solid #F2F2F2;
      position: absolute;
      top: 8px;
      left: -15px;
    }
  }

  .spanBoxRight {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    max-width: 80%;
    border-radius: 10px;
    background-color: #01A6FB;
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    text-align: left;
    position: relative;
    text-align: center;
    line-height: 26px;

    &::before {
      display: inline-block;
      content: "";
      border: 8px solid transparent;
      border-left: 8px solid #01A6FB;
      position: absolute;
      top: 8px;
      right: -15px;
    }
  }

  .headPic {
    margin: 0 15px;
    width: 42px;
    height: 42px;
    background-color: #01A6FB;
    border: 2px solid #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    line-height: 40px;
    font-size: 24px;
    color: #fff;

    .headPicInner {
      width: 100%;
      height: 100%;
      background-image: url('../../../../../../assets/images/logo.png');
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .timeBox {
    text-align: center;
  }

  .questionsBox {
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
  }

  .answersBox {
    padding: 15px 0;
    // background-color: #F2F2F2;
    display: flex;
  }

  .emptylog {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
    }
  }

}

.searchResultBox {
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 14px;

  .searchButton {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
}

.highLight {
  font-size: 20px;
  color: #1d84f6;
  font-weight: bold;
}

.active-bg {
  background: #584d43;
  color: #c1ba7d;
}

.a-btn {
  margin-left: 6px;
}

.currentSearchWord {
  background-color: pink;
}

.el-image {
  /deep/ .image-error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ddd;

    span {
      margin-top: 5px;
    }
  }
}
</style>
